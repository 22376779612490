/* eslint-disable react/no-array-index-key */
/* global tw */
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

import Lightbox from '../components/lightbox'

const Grid = styled('div')`
  ${tw('grid grid-cols-1 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 gap-2')};
`

const FluidImage = styled(Img)`
  ${tw('rounded shadow-lg cursor-pointer')};
`

const Photos = () => (
  <StaticQuery
    query={graphql`
      {
        allFile(
          filter: { extension: { eq: "jpg" }, relativeDirectory: { eq: "photos" } }
          sort: { fields: name, order: DESC }
        ) {
          edges {
            node {
              id
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Grid>
        {data.allFile.edges.map(({ node }, index) => (
          <Lightbox key={index} fluidImage={node.childImageSharp.fluid}>
            <FluidImage fluid={node.childImageSharp.fluid} />
          </Lightbox>
        ))}
      </Grid>
    )}
  />
)

export default Photos
/* eslint-enable react/no-array-index-key */
