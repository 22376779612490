/* global tw */
import React, { useEffect, useState } from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import useSSR from 'use-ssr'

const Container = styled('div')`
  ${tw('')};
`

const Popup = styled('div')`
  ${tw('fixed z-10 inset-0 w-screen h-screen bg-black bg-opacity-75 cursor-pointer')};
`

const FluidImage = styled(Img)`
  ${tw('fixed inset-1/2 rounded')};
  width: 1000px;
  transform: translate(-50%, -50%);
`

const Lightbox = (props) => {
  const { children, fluidImage } = props
  const { isBrowser } = useSSR()
  const [isOpen, setIsOpen] = useState(false)
  const [isMobile, setIsMobile] = useState({
    matches: isBrowser ? window.innerWidth <= 768 : true,
  })
  const toggleIsOpen = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px')
    mediaQuery.addListener(setIsMobile)

    return () => mediaQuery.removeListener(setIsMobile)
  })

  return (
    <Container onClick={isMobile && !isMobile.matches ? toggleIsOpen : null}>
      {children}
      {isOpen ? (
        <Popup onClick={toggleIsOpen}>
          <FluidImage fluid={fluidImage} />
        </Popup>
      ) : null}
    </Container>
  )
}

Lightbox.propTypes = {
  children: PropTypes.node.isRequired,
  fluidImage: PropTypes.node.isRequired,
}

export default Lightbox
